import React, { useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { HashLink as Link } from 'react-router-hash-link'; // Importation de HashLink
import '../../css/hamburgers.css';
import logo from "../../assets/images/logo.png"

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Navbar expand="lg">
      <Navbar.Brand as={Link} to="/"><img src={logo} alt="Logo" style={{ width: '115px' }}/></Navbar.Brand>
      <button
        className={`hamburger hamburger--collapse mobile ${isOpen ? 'is-active' : ''}`}
        type="button"
        onClick={toggleMenu}
        aria-controls="basic-navbar-nav"
        aria-expanded={isOpen}
        aria-label="Toggle navigation"
      >
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </button>
      <Navbar.Collapse id="basic-navbar-nav" className={isOpen ? 'show' : ''}>
        <Nav className="ml-auto">
          <Nav.Link as={Link} to="/#about" onClick={toggleMenu}>À PROPOS</Nav.Link>
          <Nav.Link as={Link} to="/#services" onClick={toggleMenu}>SERVICES</Nav.Link>
          <Nav.Link as={Link} to="/#equipe" onClick={toggleMenu}>ÉQUIPES</Nav.Link>
          <Nav.Link as={Link} to="/#galerie" onClick={toggleMenu}>GALERIE</Nav.Link>
          <Nav.Link as={Link} to="/contact" onClick={toggleMenu}>CONTACT</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Navigation;
