import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import imgArtiste from "../../assets/images/artiste.jpg"
import imgArtisteProfil from "../../assets/profil-artiste.svg"
import imgGaelle from "../../assets/images/team/gael_Barthelemy.jpg"
import imgFlorian from "../../assets/images/team/florian.jpg"
import imgAudrey from "../../assets/images/team/audrey.jpg"
import imgRichard from "../../assets/images/team/richardp.png"
import imgOlivia from "../../assets/images/team/olivia.jpg"
import imgSebastien from "../../assets/images/team/blancardi.jpg"
import imgLionel from "../../assets/images/team/highlander.jpg"
import imgMaryne from "../../assets/images/team/smaryne.jpg"
import imgEddy from "../../assets/images/team/emalacarne.jpg"
import imgStephane from "../../assets/images/team/smolineris.jpg"
import imgLaura from "../../assets/images/team/bourguignon.jpg"
import imgElisa from "../../assets/images/team/ecavatorta.jpg"
import imgEvan from "../../assets/images/team/evand.jpg"
import imgBenoit from "../../assets/images/team/Benoit.JPG"
import imgRobin from "../../assets/images/team/ROBIN.jpeg"
import PresentationArtistes from "./PresentationArtistes";


const Artistes = () => {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/contact');
    };

    return (
      <>
        <section id="services" className="mt-5">
          <Container>
            <Row className="bg-service justify-content-center">
              <Col lg={12} className="container-service-titre">
              <h1 className="services">SERVICES</h1>
              </Col>
              <Col lg={6} pt={5} pb={9} md={6} className="px-5 pb-5">
                <h3 className="party service-sstitre">Party</h3>
                <p className="des-ballades-damour-container">
                 <span className="nous-vous-remercions">{`Des ballades d’amour classiques en direct aux airs plus entraînants,  plus vivants et plus optimistes ! C’est votre soirée ! C’est LA soirée ! La MEILLEURE soirée !`}</span>
               </p>
              </Col>
              <Col lg={6} pt={5} md={6} className="px-5 pb-8">
                <h3 className="after service-sstitre">After</h3> 
                <p className="the-show-must-container">
                 <span className="nous-vous-remercions">The show must go on ! Faites durer la nuit encore plus longtemps avec nos DJs résidents les plus prestigieux et les plus estimés.</span>
               </p>
              </Col>
              <Col lg={6} pt={5} pb={9} md={6} className="px-5 pb-8">
                <h3 className="wedding3 service-sstitre">Wedding</h3>
                <p className="nos-artistes-ne-container">
                  <span className="nous-vous-remercions">{`Nos artistes ne sont pas seulement des interprètes, ils sont avant tout des artistes de scène. Eux seuls ont le secret des pistes de Danseuse bondées et des soirées enflammées. `}</span>
                  <span className="nous-vous-remercions">&nbsp;</span>
                  <span className="nous-vous-remercions">{`C’est leur style et leur créativité, à travers leur répertoire actuel, qui vous transporteront, vous et vos invités.`}</span>
                </p>
                <button className="groupe-36 desktop" disabled={false} onClick={handleClick}>
                  <b className="contacter-nous">Contacter nous</b>
                </button>
              </Col>
              <Col lg={6} pt={5} pb={9} md={6} className="px-5 pb-5">
                <h3 className="party service-sstitre">Food Truck</h3>
                <p className="des-ballades-damour-container">
                 <span className="nous-vous-remercions">{`Découvrez notre Food Truck B.P.M, une expérience culinaire unique pour tous vos événements !
                 Mariages, séminaires, festivals…Offrez à vos invités des saveurs authentiques, sucrées et salées, où que vous soyez.`}</span>
               </p>
               <button className="groupe-36 mobile" disabled={false} onClick={handleClick}>
                  <b className="contacter-nous">Contacter nous</b>
                </button>
              </Col>
            </Row>
          </Container>
        </section>
        <section id="equipe">
          <Container>
            <Row>
              <Col lg={6} md={12} className="mb-5 mr-">
                <img src={imgArtiste} alt="artiste" className="img-fluid" />
              </Col>
              <Col lg={6} md={12}>
                <h2 className="rencontre-avec">{`RENCONTRE AVEC `}</h2>
                <h1 className="nos-artistes">Nos artistes</h1>
                <p className="nos-artistes-ne-container mb-5">
                  <span className="nous-vous-remercions">{`Nos artistes ne sont pas seulement des interprètes, ils sont avant tout des artistes de scène. Eux seuls ont le secret des pistes de Danseuse bondées et des soirées enflammées.`}</span>
                  <span className="nous-vous-remercions">&nbsp;</span>
                  <span className="nous-vous-remercions">{`C’est leur style et leur créativité, à travers leur répertoire actuel, qui vous transporteront, vous et vos invités.`}</span>
                </p>
                <Row>
                  <Col lg={6} md={6} className="container-profil-info mb-4">
                    <PresentationArtistes 
                        name="Florian Chiane"
                        title="Chef d’orchestre & DJ"
                        
                        imgSrc={imgFlorian}
                        imgAlt="DJ"
                    />
                  </Col>
                  <Col lg={6} md={6} className="container-profil-info mb-4">
                    <PresentationArtistes 
                        name="Benoît"
                        title="Guitariste"
                        
                        imgSrc={imgBenoit}
                        imgAlt="Guitariste"
                    />
                  </Col>
                  <Col lg={6} md={6} className="container-profil-info mb-4">
                    <PresentationArtistes 
                        name="Gaëlle"
                        title="Batteur"
                        
                        imgSrc={imgGaelle}
                        imgAlt="Batteur"
                    />
                  </Col>
                  <Col lg={6} md={6} className="container-profil-info mb-4">
                    <PresentationArtistes 
                        name="Stéphane"
                        title="Bassiste"
                        
                        imgSrc={imgStephane}
                        imgAlt="Bassiste"
                    />
                  </Col>
                  <Col lg={6} md={6} className="container-profil-info mb-4">
                    <PresentationArtistes 
                        name="Robin"
                        title="Chanteur"
                        
                        imgSrc={imgRobin}
                        imgAlt="Chanteur"
                    />
                  </Col>
                  {/* <Col lg={6} md={6} className="container-profil-info mb-4">
                    <PresentationArtistes 
                        name="Sébastien"
                        title="Chanteur"
                        
                        imgSrc={imgSebastien}
                        imgAlt="Chanteur"
                    />
                  </Col> */}
                  <Col lg={6} md={6} className="container-profil-info mb-4">
                    <PresentationArtistes 
                        name="Audrey"
                        title="Chanteuse"
                        
                        imgSrc={imgAudrey}
                        imgAlt="Chanteuse"
                    />
                  </Col>
                  <Col lg={6} md={6} className="container-profil-info mb-4">
                    <PresentationArtistes 
                        name="Maryne"
                        title="Chanteuse"
                        
                        imgSrc={imgMaryne}
                        imgAlt="Chanteuse"
                    />
                  </Col>
                  {/* <Col lg={6} md={6} className="container-profil-info mb-4">
                    <PresentationArtistes 
                        name="Martial"
                        title="Ingénieur son"
                        
                        imgSrc={imgArtisteProfil}
                        imgAlt="Technicien"
                    />
                  </Col>
                  <Col lg={6} md={6} className="container-profil-info mb-4">
                    <PresentationArtistes 
                        name="Evan"
                        title="Technicien son"
                        
                        imgSrc={imgEvan}
                        imgAlt="Technicien"
                    />
                  </Col>
                  <Col lg={6} md={6} className="container-profil-info mb-4">
                    <PresentationArtistes 
                        name="Richard"
                        title="Ingénieur lumière"
                        
                        imgSrc={imgRichard}
                        imgAlt="Technicien"
                    />
                  </Col>
                  <Col lg={6} md={6} className="container-profil-info mb-4">
                    <PresentationArtistes 
                        name="Benjamin"
                        title="Technicien"
                        subtitle="technicien"
                        imgSrc={imgArtisteProfil}
                        imgAlt="Technicien"
                    />
                  </Col>
                  <Col lg={6} md={6} className="container-profil-info mb-4">
                    <PresentationArtistes 
                        name="Claire"
                        title="Technicienne"
                        
                        imgSrc={imgArtisteProfil}
                        imgAlt="Technicienne"
                    />
                  </Col>
                  <Col lg={6} md={6} className="container-profil-info mb-4">
                    <PresentationArtistes 
                        name="Melanie"
                        title="Technicienne"
                        
                        imgSrc={imgArtisteProfil}
                        imgAlt="Technicienne"
                    />
                  </Col>
                  <Col lg={6} md={6} className="container-profil-info mb-4">
                    <PresentationArtistes 
                        name="Olivia"
                        title="Danseuse"
                        
                        imgSrc={imgOlivia}
                        imgAlt="Danseuse"
                    />
                  </Col>
                  <Col lg={6} md={6} className="container-profil-info mb-4">
                    <PresentationArtistes 
                        name="Elisa"
                        title="Danseuse"
                        
                        imgSrc={imgElisa}
                        imgAlt="Danseuse"
                    />
                  </Col>
                  <Col lg={6} md={6} className="container-profil-info mb-4">
                    <PresentationArtistes 
                        name="Laura"
                        title="Danseuse"
                        
                        imgSrc={imgLaura}
                        imgAlt="Danseuse"
                    />
                  </Col> */}
                </Row>
              </Col>
            </Row>
          </Container>

        </section>
      </> 
    );
}

export default Artistes;