import React, { useState } from 'react';
import { Carousel, Container, Row, Col } from 'react-bootstrap';

// Import dynamique de toutes les images du dossier "galerie"
const importAll = (r) => r.keys().map(r);

const Galerie = () => {
  // On importe les images depuis le dossier
  const images = importAll(require.context('../../assets/images/galerie', false, /\.(png|jpe?g|svg)$/));

  // On groupe les images par paire pour afficher 2 images par slide
  const groupedImages = [];
  for (let i = 0; i < images.length; i += 2) {
    groupedImages.push(images.slice(i, i + 2));
  }

  // State pour l'index actif du carousel
  const [activeIndex, setActiveIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setActiveIndex(selectedIndex);
  };

  return (
    <section className="groupe-42" id="galerie">
      <Container>
        <Row>
          <div className="container-titre-galerie">
            <h1 className="titre-galerie">GALERIE</h1>
          </div>
          <Col md={12}>
            <h2 className="dcouvrez-nos-derniers">DÉCOUVREZ Nos Derniers événements</h2>
          </Col>
        </Row>

        {/* Carousel principal affichant 2 images par slide */}
        <Row>
          <Col md={12}>
            <Carousel activeIndex={activeIndex} onSelect={handleSelect} interval={3000}>
              {groupedImages.map((group, index) => (
                <Carousel.Item key={index}>
                  <Row>
                    {group.map((img, idx) => (
                      <Col md={6} key={idx}>
                        <img
                          className="d-block w-100 carousel-image"
                          src={img.default || img}
                          alt={`Image ${index * 2 + idx + 1}`}
                        />
                      </Col>
                    ))}
                  </Row>
                </Carousel.Item>
              ))}
            </Carousel>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Galerie;



// import { Container, Row, Col } from "react-bootstrap";
// import GalerieEvent from "./GalerieEvent";
// import imgTest from "../../assets/images/roquebilière_festin.jpg";


// const Galerie = () => {
//     return (
//       <section className="groupe-42" id="galerie">
//         <Container>
//           <Row>
//             <div className="container-titre-galerie">
//               <h1 className="titre-galerie">GALERIE</h1>
//             </div>
//             <Col md={12}>
//               <h2 className="dcouvrez-nos-derniers">DÉCOUVREZ Nos Derniers événements</h2>
//             </Col>
//           </Row>
//           <Row className="galerie-row">
//             <GalerieEvent
//               lieu="Roquebillère"
//               type="Festin"
//               img={imgTest}
//               imgAlt="Image Festin"
//             />
//             <GalerieEvent
//               lieu="Roquebillère"
//               type="Festin"
//               img={imgTest}
//               imgAlt="Image Festin2"
//             />
//             <GalerieEvent
//               lieu="Roquebillère"
//               type="Festin"
//               img={imgTest}
//               imgAlt="Image Festin3"
//             />
//             <GalerieEvent
//               lieu="Roquebillère"
//               type="Festin"
//               img={imgTest}
//               imgAlt="Image Festin4"
//             />
//             <GalerieEvent
//               lieu="Roquebillère"
//               type="Festin"
//               img={imgTest}
//               imgAlt="Image Festin5"
//             />
//           </Row>
//         </Container>
//       </section>
//     );
// }

// export default Galerie;





