import { Container, Row, Col } from "react-bootstrap";
import Facebook from "../../assets/images/facebook.svg";
import Instagram from "../../assets/images/instagram.svg";
import Youtube from "../../assets/images/youtube.svg";
import ThemeProvider from 'react-bootstrap/ThemeProvider'
import logo from "../../assets/images/logo.png"

const CustomBreakpoints = {
  breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
};

const Footer = () => {
    return (
      <footer className="groupe-53">
          <ThemeProvider breakpoints={CustomBreakpoints.breakpoints}>
            <Container>
              <Row >
                <Col md={12} className="d-flex flex-column align-items-center text-center">
                  <h1 className="follow-us">Follow us</h1>
                </Col>
                <Col md={12}>
                  <Row className="justify-content-center mt-5">
                    <Col md={2} xs={4} className="d-flex flex-column align-items-center text-center">
                      <a target="_blank" href="https://www.youtube.com/@Orchestrebpm06"><img className="groupe-50-icon" alt="" src={Youtube} /></a>
                    </Col>
                    <Col md={2} xs={4} className="d-flex flex-column align-items-center text-center">
                      <a target="_blank" href="https://www.facebook.com/orchestrebpm"><img className="groupe-52-icon" alt="" src={Facebook} /></a>
                    </Col>
                    <Col md={2} xs={4} className="d-flex flex-column align-items-center text-center">
                      <a target="_blank" href="https://www.instagram.com/orchestre.bpm/"><img className="groupe-51-icon" alt="" src={Instagram} /></a>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <hr/>
                </Col>
              </Row>
              <Row>
                <Col md={3} lg={3}>
                  <p className="bpm-event-1-place-container">
                    <span className="nous-vous-remercions">
                      <span>
                        <span className="bpm-event1">Bpm events</span>
                      </span>
                    </span>
                    <span className="nous-vous-remercions">
                      <span>&nbsp;</span>
                    </span>
                    <a target="_blank" href="https://maps.app.goo.gl/kyiGv6oGKZTRzu6x7">
                      <span className="nous-vous-remercions">
                        
                        {/* <span className="place-de-lglise1">{`1 place de l’église, `}</span> */}
                      </span>
                      <span className="nous-vous-remercions">
                        <span className="place-de-lglise1">65 avenue Boutiny, 06530 Peymeinade</span>
                      </span>
                    </a>
                  </p>
                </Col>
                <Col md={3} lg={3}>
                  <p className="t-33-container">
                    {/* <a href="tel:+33142120317"><span className="nous-vous-remercions"> +33 (0)1 42 12 03 17</span></a> */}
                    <a href="tel:+33603046501"><span className="nous-vous-remercions">M / +33 6 03 04 65 01</span></a>
                    <a href="mailto:orchestre.bpm@gmail.com"><span className="nous-vous-remercions">E / orchestre.bpm@gmail.com</span></a>
                  </p>
                </Col>
                <Col md={3} className="desktop "></Col>
                <Col md={3} lg={3} className="d-flex flex-column text-end">
                  <a href="#"><b className="nous-vous-remercions bpm2"><img src={logo} alt="Logo" style={{ width: '115px' }}/></b></a>
                </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-center">
                  <span class="pq-copyright">
                    {/* Copyright&nbsp;
                    <script>
                      document.write(new Date().getFullYear())
                    </script> */}
                      {/* 2024&nbsp;Monkeys - Tous droits réservés. -  */}
                      Made with&nbsp;💙&nbsp;by&nbsp;
                    <a target="_blank" href="https://maelcorp.com" rel="noreferrer">MaelCorp.</a>
                  </span>
              </Col>
            </Row>
          </Container>
        </ThemeProvider>
      </footer>
    );
}

export default Footer;