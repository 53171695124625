import { Container, Row, Col } from "react-bootstrap";

const Informations = () => {
    return ( 
        <Row className="desktop-flex">
            <Col lg={1}>
                <hr className="separateur-contact"></hr>
            </Col>
            <Col lg={10}>
                <Row>
                    <Col lg={12} className="mb-5">
                    <b className="adresse-1-place-de-container">
                        <p className="adresse">
                        <span>
                            <span className="contact-sstitre">Adresse</span>
                        </span>
                        </p>
                        <a href="https://maps.app.goo.gl/kyiGv6oGKZTRzu6x7">
                            <span className="nous-vous-remercions">
                            
                            <span className="place-de-lglise1">65 avenue Boutiny,</span>
                            </span>
                            <span className="nous-vous-remercions">
                            <span className="place-de-lglise1"> 06530 Peymeinade</span>
                            </span>
                        </a>
                    </b>
                </Col>
                    <Col lg={12} className="mb-5">
                        <b className="support-t-33-container">
                            {/* <a href="tel:+33142120317"><span className="nous-vous-remercions">+33 (0)1 42 12 03 17</span></a> */}
                            <a href="tel:+33603046501"><span className="nous-vous-remercions">M / +33 6 03 04 65 01</span></a>
                            <a href="mailto:orchestre.bpm@gmail.com"><span className="nous-vous-remercions">E / orchestre.bpm@gmail.com</span></a>
                        </b>
                    </Col>
                    <Col lg={12} className="mb-5">
                        <b className="ouverture-lundi-vendredi-10-container">
                            <p className="contact-sstitre">Ouverture</p>
                            <p className="adresse">
                            <span>Lundi / vendredi</span>
                            </p>
                            <p className="adresse">10h00 - 17h00</p>
                        </b>
                    </Col>
                </Row>
            </Col>
            

        </Row>   

    );
}

export default Informations;